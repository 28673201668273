import './App.css';
import { FaInstagram, FaSteam } from 'react-icons/fa';

function App() {
  return (
    <div className="App">
      {/* Video de fondo de YouTube */}
      <div className="video-background">
        <iframe 
          src=""
          frameBorder="0"
          allow="autoplay; encrypted-media"
          allowFullScreen
          title="YouTube video background"
        ></iframe>
      </div>

      {/* Contenido de la tarjeta */}
      <div className="neon-card">
        <h1 className="name">fazito...</h1>
        <div className="social-links">
          <a 
            href="https://www.instagram.com/vaaalgmez" 
            target="_blank" 
            rel="noopener noreferrer"
            className="instagram-link icon-button"
          >
            <FaInstagram className="instagram-icon" />
          </a>
          <a 
            href="https://steamcommunity.com/profiles/76561199522580900/" 
            target="_blank" 
            rel="noopener noreferrer"
            className="steam-link icon-button"
          >
            <FaSteam className="steam-icon" />
          </a>
        </div>
      </div>
    </div>
  );
}

export default App;
